<template>
	<span>
		<span v-if="user">
			{{ user[column.value] }}
		</span>
	</span>
</template>

<script>
export default {
	name: "UserCell",
	props: ["item", "column"],
    computed: {
		user(){
			return this.$store.state.users.data[this.item.user];
		},
    }
};
</script>
